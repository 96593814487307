;(function($) {
	personalization = {
		init: function() {},
		login: function() {
			$.post("/personalization/headerlinks", function(data) {
				$(".header-links-container").html(data.personalizedLink);
			});
		},
		mobilecart: function() {
			$.post("/personalization/mobilecart", function(data) {
				$(".mobile-cart").html(data.personalizedLink);
			});
		},
		mobilesigninlink: function() {
			$.post("/personalization/mobilesignin", function(data) {
				$(".mobile-signin-link").html(data.personalizedLink);
			});
		},
		mobileregisterlink: function() {
			$.post("/personalization/mobileregister", function(data) {
				$(".mobile-register-link").html(data.personalizedLink);
			});
		}
	};
})(jQuery);
